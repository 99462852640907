import cn from 'classnames';
import type { FC } from 'react';

import Link from '@components/ui/Link';
import Text from '@components/ui/Text/Text';
import { getContentfulImgSet, renderResponsiveImage } from '@lib/image';

import type { ClickableProps, NavigationItem } from '../types';

import s from './MenuCard.module.scss';

export const MenuCard: FC<ClickableProps<NavigationItem> & { totalCount: number }> = ({
  title,
  image,
  description,
  url,
  onClick,
  totalCount,
}) => (
  <div className={s.root}>
    <Link href={url} aria-label={title} prefetch={false} onClick={onClick}>
      <div className="mb-[18px]">
        {image?.url
          ? renderResponsiveImage(getContentfulImgSet(image, undefined, ['sm']), {
              loading: 'lazy',
              className: cn(s.image, {
                [s.large]: totalCount <= 2,
                [s.medium]: totalCount > 2 && totalCount <= 4,
                [s.small]: totalCount > 4,
              }),
            })
          : null}
      </div>
      <Text variant="header-5" className="text-left text-black" asElement="p">
        {title}
      </Text>
      {description && (
        <Text variant="xsmall" className="text-left text-gray-50 mt-3">
          {description}
        </Text>
      )}
    </Link>
  </div>
);
