import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { FC, MouseEventHandler, RefObject, useCallback, useState } from 'react';

import ClickOutside from '@lib/click-outside';

import SearchInput from '../SearchInput/SearchInput';

import style from './SearchOverlay.module.scss';

interface Props {
  value: string;
  show?: boolean;
  onSearch: () => void;
  onChange: (val: string) => void;
  onClear: MouseEventHandler;
  onFocus: () => void;
  inputRef: RefObject<HTMLInputElement>;
  onToggle: (state: boolean) => void;
}

const SearchOverlay: FC<Props> = ({
  value: initialValue,
  show = false,
  onSearch,
  onChange,
  onFocus,
  inputRef,
  onClear,
  onToggle,
}) => {
  const { t } = useTranslation(['product']);
  const [isOpen, setIsOpen] = useState(show);

  const handleSearch = useCallback(() => {
    setIsOpen(false);
    onSearch();
  }, [onSearch]);

  const handleClick = useCallback(() => {
    if (onToggle) {
      onToggle(!isOpen);
    }

    setIsOpen(!isOpen);
  }, [onToggle, isOpen]);

  return (
    <ClickOutside active={isOpen} onClick={() => setIsOpen(false)}>
      <div className="relative">
        <button
          data-testid="search-button"
          aria-label={t('product:search.placeholder.normal')}
          type="button"
          onClick={handleClick}
          className={style.iconContainer}
        >
          <FontAwesomeIcon className="menu-icon text-black" icon={faSearch} />
        </button>
        {isOpen && (
          <div className={style.root}>
            <SearchInput
              inputRef={inputRef}
              value={initialValue}
              placeholder={t('product:search.placeholder.inFocus')}
              onSearch={handleSearch}
              onChange={onChange}
              onClear={onClear}
              onFocus={onFocus}
            />
          </div>
        )}
      </div>
    </ClickOutside>
  );
};

export default SearchOverlay;
